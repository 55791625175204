import React, { useLayoutEffect, useState } from "react";
import { Navbar as BsNavbar, Nav, NavDropdown } from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useLocation } from "@reach/router";
import Img from "gatsby-image";
import routes from "@utils/routes";

const query = graphql`
  query NavQuery {
    logo: file(relativePath: { eq: "logo.svg" }) {
      publicURL
    }
    hamburgerIcon: file(relativePath: { eq: "icon/hamburger.svg" }) {
      publicURL
    }
    closeIcon: file(relativePath: { eq: "icon/icon-close.svg" }) {
      publicURL
    }
    btnWA: file(relativePath: { eq: "btn-wa.png" }) {
      childImageSharp {
        fixed(width: 372, height: 66, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Navbar = () => {
  const { logo, hamburgerIcon, closeIcon, btnWA } = useStaticQuery(query);
  const { pathname, search } = useLocation();

  const [navIsOpen, setNavIsOpen] = useState<boolean>(false);
  const [transparent, setTransparent] = useState<boolean>(true);

  useLayoutEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 0) {
        setTransparent(false);
      } else {
        setTransparent(true);
      }
    };

    onScroll();

    window.addEventListener("scroll", onScroll);

    return () => {
      window.addEventListener("scroll", onScroll);
    };
  }, []);

  const handleToggleNav = () => {
    setNavIsOpen(!navIsOpen);
  };

  const isCurrentPage = (route: string) => {
    return route === pathname ? "active" : "";
  };

  const renderMobileNav = () => {
    return routes.slice(0, 4).map(route => {
      if (route.subMenu) {
        if (route.path.includes("portofolio")) {
          return (
            <NavDropdown
              title={route.label}
              id="collasible-nav-dropdown"
              key={route.path}
            >
              {route.subMenu.slice(0, 5).map(subRoute => (
                <NavDropdown.Item
                  as={Link}
                  to={`${subRoute.path}${search}`}
                  key={subRoute.path}
                  className={isCurrentPage(subRoute.path)}
                >
                  {subRoute.label}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          );
        } else {
          return route.subMenu.slice(0, 5).map(subRoute => (
            <Nav.Link
              as={Link}
              to={`${subRoute.path}${search}`}
              key={subRoute.path}
              className={isCurrentPage(subRoute.path)}
            >
              {subRoute.label}
            </Nav.Link>
          ));
        }
      } else {
        return (
          <Nav.Link
            as={Link}
            to={`${route.path}${search}`}
            key={route.path}
            className={isCurrentPage(route.path)}
          >
            {route.label}
          </Nav.Link>
        );
      }
    });
  };

  const renderDesktopNav = () => {
    return routes.slice(0, 4).map(route => {
      if (route.subMenu) {
        return (
          <NavDropdown
            title={route.label}
            id="collasible-nav-dropdown"
            key={route.path}
          >
            {route.subMenu.slice(0, 5).map(subRoute => (
              <NavDropdown.Item
                as={Link}
                to={`${subRoute.path}${search}`}
                key={subRoute.path}
                className={isCurrentPage(subRoute.path)}
              >
                {subRoute.label}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        );
      } else {
        return (
          <Nav.Link
            as={Link}
            to={`${route.path}${search}`}
            key={route.path}
            className={isCurrentPage(route.path)}
          >
            {route.label}
          </Nav.Link>
        );
      }
    });
  };

  return (
    <nav
      className={`navbar navbar-expand-lg fixed-top ${
        transparent ? `bg-transparent` : ``
      }`}
    >
      <BsNavbar.Brand as={Link} to={`/${search}`}>
        <img src={logo.publicURL} alt="logo" />
      </BsNavbar.Brand>
      <BsNavbar.Toggle
        aria-controls="responsive-navbar-nav"
        onClick={handleToggleNav}
      >
        <span
          className="navbar-toggler-icon"
          style={{
            backgroundImage: `url(${hamburgerIcon.publicURL})`,
          }}
        />
      </BsNavbar.Toggle>
      <BsNavbar.Collapse id="responsive-navbar-nav" className="desktop-menu">
        <Nav className="ml-auto navbar-nav main-menu">
          {renderDesktopNav()}
          <Nav.Link as={Link} to={`/form/${search}`} key="form">
            <span>HUBUNGI KAMI DISINI</span>
          </Nav.Link>
        </Nav>
      </BsNavbar.Collapse>
      <div className={`mobile-menu ${navIsOpen ? `show` : ``}`}>
        <div className={`logo-container ${navIsOpen ? `show` : ``}`}>
          <img src={logo.publicURL} alt="logo" />
        </div>
        <div className={`main-menu ${navIsOpen ? `show` : ``}`}>
          <div className="close-btn" onClick={handleToggleNav}>
            <img src={closeIcon.publicURL} alt="close button" />
          </div>
          <Nav className="navbar-nav">
            {renderMobileNav()}
            <a
              href="https://www.instagram.com/gudangseragam/"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
            >
              Instagram
            </a>
            <a
              href="https://www.youtube.com/channel/UCIMaY8YjCb9qisvLS9M27Uw"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
            >
              Youtube
            </a>
            <Link to={`/form/${search}`} className="btn-wa">
              <Img fixed={btnWA.childImageSharp.fixed} />
            </Link>
          </Nav>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
