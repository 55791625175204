import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Button } from "react-bootstrap";
import SEO from "@components/SEO";
import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import { useLocation } from "@reach/router";

interface LayoutProps {
  noFooter?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, noFooter = false }) => {
  const { search, pathname } = useLocation();
  const [showContactBtn, setShowContactBtn] = useState<boolean>(false);

  function onScroll() {
    if (pathname !== "/") {
      setShowContactBtn(true);
      return;
    }

    if (window.scrollY > window.innerHeight) setShowContactBtn(true);
    else setShowContactBtn(false);
  }

  useEffect(() => {
    onScroll();

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  return (
    <main>
      <SEO />
      <Navbar />
      {children}
      {!noFooter && <Footer />}
      <Button
        as={Link}
        to={`/form/${search}`}
        className={`floating-contact-us ${showContactBtn ? `show` : ``}`}
      >
        HUBUNGI KAMI DISINI!
        <img
          src={require("@assets/images/icon/wa.svg")}
          className={'floating-contact-us-icon'}
          alt="whatsapp"
        />
      </Button>
    </main>
  );
};

export default Layout;
