interface Routes {
  path: string;
  label: string;
  subMenu?: Routes[];
}

const routes: Routes[] = [
  {
    "path": "/",
    "label": "Home",
  },
  {
    "path": "/portofolio/",
    "label": "Portofolio",
    "subMenu": [
      {
        "path": "/portofolio/seragam-kemeja/",
        "label": "Seragam/Kemeja",
      },
      {
        "path": "/portofolio/jaket/",
        "label": "Jaket",
      },
      {
        "path": "/portofolio/polo/",
        "label": "Polo",
      },
      {
        "path": "/portofolio/wearpack/",
        "label": "Wearpack",
      },
      {
        "path": "/portofolio/rompi/",
        "label": "Rompi",
      },
    ],
  },
  {
    "path": "/klien-kami/",
    "label": "Klien Kami",
  },
  {
    "path": "",
    "label": "Info Lainnya",
    "subMenu": [
      {
        "path": "/layanan-gratis/",
        "label": "Layanan Gratis",
      },
      {
        "path": "/contoh-desain/",
        "label": "Contoh Desain",
      },
      {
        "path": "/cara-order/",
        "label": "Cara Order",
      },
      {
        "path": "/bahan/",
        "label": "Bahan",
      },
      {
        "path": "/standar-ukuran/",
        "label": "Standar Ukuran",
      },
      {
        "path": "/tentang-kami/",
        "label": "Tentang Kami",
      },
      {
        "path": "/form/",
        "label": "Hubungi Kami",
      },
      {
        "path": "/contact/",
        "label": "Contact",
      },
    ],
  },
];

export default routes;
