import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import styles from "./footer.module.scss";

const query = graphql`
  query FooterQuery {
    # QUERY LOGO
    logo: file(relativePath: { eq: "logo.svg" }) {
      publicURL
    }
    igIcon: file(relativePath: { eq: "icon/ig.svg" }) {
      publicURL
    }
    ytIcon: file(relativePath: { eq: "icon/yt.svg" }) {
      publicURL
    }
  }
`;

const Footer = () => {
  const { logo, igIcon, ytIcon } = useStaticQuery(query);

  return (
    <footer className={styles.footer}>
      <div className={styles.left}>
        <img src={logo.publicURL} alt="logo" className={styles.logo} />
        <p>
          Berlokasi di Jakarta, kami adalah perusahaan konveksi Seragam Kerja
          yang sudah berpengalaman selama bertahun-tahun menangani kebutuhan
          seragam kerja. Kami memiliki tim costumer service yang mampu merespon
          dengan cepat segala pertanyaan maupun hal-hal yang ingin diketahui
          oleh klien. Dengan seleksi ketat yang kami lakukan, kami telah
          memiliki penjahit – penjahit seragam kerja yang memiliki kualitas
          jahitan rapi khas tailor. Ditambah dengan tim quality control yang
          berpengalaman, kami bisa menjamin dan memastikan bahwa barang yang
          dipesan sesuai dengan keinginan klien baik, kerapian, ukuran maupun
          model seragam jaket. Untuk menyelesaikan problem waktu penger- jaan
          seragam kerja yang molor, kami telah membentuk tim manajemen produksi
          yang tertata sehingga mampu mendeliver order klien tepat waktu. Kami
          memiliki kapasitas produksi besar, sehingga mampu melayani order
          banyak klien dalam waktu yang bersamaan. Berkenaan dengan bahan
          material seragam kerja, kami memberikan garansi bahan material maupun
          warna sesuai dengan yang disepakati dengan klien di awal. Dan
          tentunya, kami bisa memberikan harga yang terjangkau, tanpa mengurangi
          kualitas bahan dan kualitas jahitan. Silakan hubungi CS kami, dengan
          senang hati CS kami akan melayani serta menjawab segala pertanyaan
          Anda.
        </p>
        <p className={styles.address}>
          CV. GUDANG SERAGAM INDONESIA<br />Jalan Dewi Sartika No.38 Pancoran Mas Depok, Kota Depok,
          Jawa Barat 16431
        </p>
      </div>
      <div className={styles.right}>
        <p>IKUTI KAMI DI</p>
        <div className={styles.socials}>
          <a
            href="https://www.youtube.com/channel/UCIMaY8YjCb9qisvLS9M27Uw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ytIcon.publicURL} alt="youtube channel" />
          </a>
          <a
            href="https://www.instagram.com/gudangseragam/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={igIcon.publicURL} alt="instagram account" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
